<template>
  <v-app style="z-index: 1">
    <template v-if="client">
      <app-bar v-if="isMobileVersion"/>
      <app-drawer v-else/>
      <app-menu/>
    </template>
    <v-container class="pa-0" style="min-height: 100dvh;max-width: 100%;width: 100%; background-color: #f2f2f2">
      <v-main :class="{'pa-0': !client}" :style="client ? {} : {height: '100dvh'}">
        <router-view/>
      </v-main>
    </v-container>
    <div
      v-if="showScrollToTop"
      class="scroll-to-top-btn"
    >
      <v-btn icon large elevation="2" style="background-color: #fff" @click="scrollToTop">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
import {mapState} from 'vuex'
import AppDrawer from './components/AppDrawer.vue'
import AppBar from '@/components/AppBar.vue'
import AppMenu from '@/components/AppMenu.vue'
import {isMobileVersion} from '@/helpers'

export default {
  name: 'App',

  components: { AppMenu, AppBar, AppDrawer },

  data: () => ({
    showScrollToTop: false,
    isMobileVersion: isMobileVersion(),
  }),

  computed: {
    ...mapState(['client']),
  },

  created() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => this.onResize());
      // window.addEventListener('scroll',  () => {
      //   this.showScrollToTop = document.body.scrollTop > 120 || document.documentElement.scrollTop > 120
      // });
    })

  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    onResize() {
      this.isMobileVersion = isMobileVersion()
    },
  }
};
</script>

<style>
html {
  overflow-y: auto !important;
}

@media (min-width: 1904px) {
  .col-xl-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.v-application {
  font-family: 'SF Pro Display', sans-serif !important;
}

.br-16 {
  border-radius: 16px !important;
}

.br-32 {
  border-radius: 32px !important;
}

.v-btn {
  text-transform: none;
}

.table-aggregate-card {
  border-color: #80717080;
}

.table-aggregate {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: center;
  color: #29292980;
  white-space: nowrap;
  min-width: 300px;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}

.v-messages {
  display: none;
}

.v-application--wrap {
  min-height: 100dvh;
}

.scroll-to-top-btn {
  position: fixed;
  right: 8px;
  bottom: 60px;
}
</style>